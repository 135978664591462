
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="card">
        <div class="card-header">
          <label class="mb-2">Filter by role</label>
          <div>
            <div v-for="(role, sIndex) in roleOptions" :key="sIndex"
               class="form-check form-check-inline">
              <input v-model="defaultRole" :id="`inlineRLRadio_${role.value}`" class="form-check-input" 
              type="radio" name="inlineRadioOptions" :value="role.value">
              <label class="form-check-label" :for="`inlineRLRadio_${role.value}`">{{role.label}}</label>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-2 gy-2">
            <div class="col-sm-12 col-md-6 d-flex">
              <div id="tickets-table_length" class="me-2 dataTables_length">
                <label class="d-inline-flex mb-0 align-items-center">
                  <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
              <div class="dropdown no-arrow me-2">
                <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri ri-filter-3-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="selectAllRows()" class="dropdown-item" type="button">
                       {{isSelectAll? 'Invert Selection' : 'Select all' }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-inline text-sm-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-input v-model="searchQuery" type="search" placeholder="Search ..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table striped hover ref="selectableTable" :items="paginatedItems"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(check)="data">
                <div style="width:3rem;" class="form-check text-center me-auto">
                  <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name || data.item.username }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.status }}</span>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <ul class="list-inline table-action m-0">
                  <li class="list-inline-item">
                    <a href="javascript:void(0);" v-if="data.item.id != activeUser.id" @click="confirmDelete(data.item.id)" class="text-danger">
                      <i class="ri-delete-bin-2-line"></i></a>
                  </li>
                </ul>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                  <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                  :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "user-list",
  props:{
    orderList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      isLoading: false,
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      defaultRole: "all",
      roleOptions:[
        { value: "all", label: "All", },{
          value: "admin", label: "Admin",
        },{ value: "worker", label: "Worker" },{
          value: "buyer", label: "Buyer",
        }
      ],
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "ID"
        },
        {
            key: "image",
            label: "Image",
            sortable: true
        },
        {
            key: "name",
            label: "Name",
            sortable: true
        },
        {
            key: "email",
            label: "Email",
            sortable: true
        },
        {
            key: "status",
            label: "Status",
            sortable: true
        },
        "action"
      ],
      selected: [],
      modalUpdateStatus: false,
      modalMarketingMail: false,
      selectedUsers: []
    }
  },
  watch: {
    "$route.query" : "refreshQueryRole"
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    usersData() {
      return this.defaultRole == "all"
             ? this.$store.state.userList.users
             : this.$store.state.userList.users.filter((u) => (u.role == this.defaultRole))
    },
    // searchedItems(){
    //   return this.usersData.filter(item => item.email ? item.email.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    // },
    searchedItems() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.usersData.filter((data) => {
          return (
              data.email ? data.email.toLowerCase().includes(search) : false ||
              data.first_name ? data.first_name.toLowerCase().includes(search) : false ||
              data.last_name ? data.last_name.toLowerCase().includes(search) : false ||
              data.username ? data.username.toLowerCase().includes(search) : false ||
              data.status ? data.status.toLowerCase().includes(search) : false
          );
        });
      } else {
          return this.usersData;
      }
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/settings/profile'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.usersData.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    refreshQueryRole(){
      if(this.$route.query.role){
        this.defaultRole = this.$route.query.role
      }
    },
    confirmDelete(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete a user",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
  },
  created() {
    this.refreshQueryRole()
    this.$store.dispatch("userList/fetchUsers")
    .then((response) => {
      this.isLoading = false;
    })
  }
}

</script>

